<template>
  <div>
    <v-card-text :style="`height: ${height}px; overflow: auto`" class="pa-0">
      <a-table-f-api
        ref="table"
        :api="m.api"
        :model="model"
        :searchable="false"
        :defaults="{
          filters: { lead_id: id },
          sort: { key: 'id', order: 'DESC' },
          paramName: 'leadActions',
        }"
        @click="onClickRow($event)"
      />
    </v-card-text>
  </div>
</template>

<script>
import { getAccess, genModel } from "@/components/mixings";
export default {
  mixins: [getAccess, genModel],
  components: {},
  props: {
    id: Number,
    data: Object,
    height: { type: Number, default: 300 },
  },
  data() {
    return {
      m: this.$store.getters["config/get"].models.leads.actions,
    };
  },
  created() {},
  watch: {
    showDialog(v) {
      if (!v) {
        this.$refs.table.updateData();
      }
    },
  },
  computed: {
    url() {
      return this.m.api;
    },
    model() {
      return this.getModelList(this.m);
    },
  },
  methods: {
    onClickRow(d) {
      return;
      if (d.field?.isMenu) {
        this.showPopupMenu(d.field.name, d.row, d.event, d);
        return;
      }
      this.showEditDialogGoodsFunc(d.row.id);
    },
  },
};
</script>
